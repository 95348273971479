import React from 'react'
import { Link } from 'gatsby'

import {
  ButtonGroup,
  Button,
  P,
  Paragraph,
  H1,
} from '@allied-solutions/affinity'

import { useDirectToFlow, useRedirectToEnterRefId } from '../../hooks'
import {
  AppLayout,
  ConfirmBorrowerInfo,
  SubmitInsuranceLayout,
} from '../../components'

const ConfirmBorrowerInfoPage = ({ location }) => {
  useRedirectToEnterRefId()
  const directToFlow = useDirectToFlow()

  return (
    <SubmitInsuranceLayout location={location}>
      <AppLayout
        leftSide={
          <>
            <Paragraph
              mb={6}
              typeStyle="labelLarge"
              textAlign="left"
              color="gray600"
            >
              Confirm
            </Paragraph>
            <H1 typeStyle="headlineMedium" fontWeight={900}>
              Here’s what we found. Does this look right?
            </H1>
            <Paragraph
              typeStyle="bodyMedium"
              textAlign="left"
              color="gray600"
              mb={3}
              mt={3}
            >
              This is what we have on file based on the information you
              provided.
            </Paragraph>
            <Paragraph typeStyle="bodyMedium" textAlign="left" color="gray600">
              If this information is accurate, click “It’s correct” to continue.
              Otherwise, click “It’s incorrect” to make changes.
            </Paragraph>
          </>
        }
        rightSide={<ConfirmBorrowerInfo />}
        footerButtons={
          <ButtonGroup
            display="flex"
            justifyContent="flex-end"
            css={`
              @media only screen and (max-width: ${props => props.theme[1]}) {
                display: grid;
                direction: rtl;
              }
            `}
          >
            <ButtonGroup.Button
              id="EnterRefId__ConfirmInfo__Button--incorrect"
              as={Link}
              to="/enter-reference-id/incorrect-info/"
              css={`
                white-space: nowrap;
                text-decoration: none;
              `}
            >
              <P
                mb={0}
                typeStyle="labelLarge"
                textAlign="right"
                color="gray600"
              >
                It’s incorrect
              </P>
            </ButtonGroup.Button>
            <Button
              id="EnterRefId__ConfirmInfo__Button--correct"
              minWidth={24}
              ml={['5', '8']}
              size="md"
              rounded
              forwardedAs={Link}
              to={directToFlow()}
              css={`
                @media only screen and (max-width: 768px) {
                  position: absolute !important;
                }
              `}
            >
              It’s correct
            </Button>
          </ButtonGroup>
        }
      />
    </SubmitInsuranceLayout>
  )
}

export default ConfirmBorrowerInfoPage
